import * as React from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "leaflet";
import Helmet from "react-helmet";

import styles from "./index.module.css";
import { LatLngExpression } from "leaflet";

const coords = {
  kirka: [59.92113, 10.71992] as LatLngExpression,
  park29: [59.92041, 10.72729] as LatLngExpression,
};

const center: LatLngExpression = [59.9203126, 10.722];

const SuperMap = () => {
  if (typeof window === "undefined") {
    return <div className={styles.map} />;
  }

  return (
    <MapContainer
      center={center}
      zoom={13}
      scrollWheelZoom={false}
      className={styles.map}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
      />
      <Marker position={coords.kirka}>
        <Popup>Her skal vi gifte oss!</Popup>
      </Marker>
      <Marker position={coords.park29}>
        <Popup>Her skal vi spise!</Popup>
      </Marker>
    </MapContainer>
  );
};

// markup
const IndexPage = () => {
  return (
    <main className={styles.main}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Thea & Ingar</title>
        <link rel="canonical" href="https://theaogingar.no" />
      </Helmet>
      <div className={styles.textStuff}>
        <h2 className={styles.date}>28. august 2021</h2>
        <h1 className={styles.heading}>Thea og Ingar</h1>

        <div className={styles.content}>
          <section className={styles.intro}>
            <p>Vielsen finner sted i Uranienborg kirke kl. 16:00.</p>
            <p>Påfølgende selskap på restaurant Park 29.</p>
          </section>

          <section>
            <p>
              <a
                href="https://listfully.no/share/bN0kqjPKw2lSxPC4OAbf"
                target="_blank"
                rel="noreferrer noopener"
                className={styles.buttonLink}
              >
                Ønskeliste finner du her
              </a>
            </p>
          </section>

          <section className={styles.praktisk}>
            <dl>
              <dt>Kleskode</dt>
              <dd>Mørk dress/smoking</dd>
              <dt>Toastmaster</dt>
              <dd>
                Asgeir Lid Almklov
                <br />
                <a href="tel:+4797708593">977 08 593</a>
              </dd>
            </dl>
          </section>
        </div>
      </div>
      <section>
        <SuperMap />
      </section>
    </main>
  );
};

export default IndexPage;
